
import Vue from "vue";
import {
  setMetaData,
  getDynamicallyImage,
  isInsideSection
} from "@/helpers/dom.ts";
import ICountUp from "vue-countup-v2";

export default Vue.extend({
  name: "AboutWho",
  metaInfo(): any {
    return setMetaData("SEO.about.who_are_we");
  },
  components: {
    ICountUp
  },
  data() {
    return {
      statisticsSectionActive: false,
      counterOptions: {
        useEasing: true,
        useGrouping: true,
        separator: " "
      }
    };
  },
  methods: {
    importImage(path: string) {
      return getDynamicallyImage(path);
    },
    handleScroll(event) {
      isInsideSection(
        document.querySelector(".about-who .story .story_items") as HTMLElement,
        isInside => (this.statisticsSectionActive = isInside)
      );
    }
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  }
});
