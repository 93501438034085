
import Vue from "vue";
import AppPageLoading from "@/components/AppPageLoading.vue";
import CookieLaw from "vue-cookie-law";
import { mapState } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    AppPageLoading,
    CookieLaw
  },
  data() {
    return {
      noAppNavUrls: ["demo", "trial"],
      noAppFooterUrls: [
        "demo",
        "trial",
        "recruitment",
        "partnership",
        "testimonial",
        "404"
      ]
    };
  },
  computed: {
    ...mapState(["pageLoading"])
  },
  async beforeMount() {
    window["Intercom"]("boot", {
      app_id: process.env.VUE_APP_INTERCOM,
      vertical_padding: 100,
      action_color: "#D83832",
      background_color: "#D83832"
    });
  }
});
