
import Vue from "vue";
import PartnersLogos from "@/components/PartnersLogos.vue";
import AppAccordion from "@/components/AppAccordion.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AppFooter",
  components: {
    PartnersLogos,
    AppAccordion
  },
  data() {
    return {
      menus: [
        {
          label: "navigation.products.label",
          submenu: [
            {
              label: "navigation.products.childs.enter.label",
              description: "navigation.products.childs.enter.description",
              to: "entrance"
            },
            {
              label: "navigation.products.childs.occupation.label",
              description: "navigation.products.childs.occupation.description",
              to: "occupation"
            },
            {
              label: "navigation.products.childs.exit.label",
              description: "navigation.products.childs.exit.description",
              to: "exit"
            },
            {
              label: "navigation.products.childs.concierge.label",
              description: "navigation.products.childs.concierge.description",
              to: "concierge"
            },
            {
              label: "navigation.products.childs.one.label",
              description: "navigation.products.childs.one.description",
              to: "one"
            },
            {
              label: "navigation.products.childs.resolve.label",
              description: "navigation.products.childs.resolve.description",
              to: "resolve"
            }
          ]
        },
        {
          label: "navigation.solutions.label",
          submenu: [
            {
              label: "navigation.solutions.childs.agents.label",
              description: "navigation.solutions.childs.agents.description",
              to: "agents"
            },
            {
              label: "navigation.solutions.childs.landlords.label",
              description: "navigation.solutions.childs.landlords.description",
              to: "landlords"
            },
            {
              label: "navigation.solutions.childs.managers.label",
              description: "navigation.solutions.childs.managers.description",
              to: "managers"
            },
            {
              label: "navigation.solutions.childs.residences.label",
              description: "navigation.solutions.childs.residences.description",
              to: "residences"
            },
            {
              label: "navigation.solutions.childs.tertiary.label",
              description: "navigation.solutions.childs.tertiary.description",
              to: "tertiary"
            },
            {
              label: "navigation.solutions.childs.seasonal.label",
              description: "navigation.solutions.childs.seasonal.description",
              to: "seasonal"
            },
            {
              label: "navigation.solutions.childs.subcontractors.label",
              description:
                "navigation.solutions.childs.subcontractors.description",
              to: "subcontractors"
            },
            {
              label: "navigation.solutions.childs.bailiffs.label",
              description: "navigation.solutions.childs.bailiffs.description",
              to: "bailiffs"
            }
          ]
        },
        {
          label: "navigation.about.label",
          submenu: [
            {
              label: "navigation.about.childs.who_are_we.label",
              to: "who_are_we"
            },
            {
              label: "navigation.about.childs.recruitment.label",
              to: "recruitment"
            },
            {
              label: "navigation.about.childs.testimonials.label",
              to: "testimonials"
            },
            {
              label: "navigation.about.childs.partners.label",
              to: "partners"
            }
          ]
        },
        {
          label: "navigation.other_information.label",
          submenu: [
            { label: "global.actions.demo", to: "demo" },
            { label: "navigation.offers.label", to: "offers" },
            {
              label: "navigation.blog.label",
              href: "https://blog.homepad.com/"
            }
          ]
        }
      ]
    };
  },
  beforeMount() {
    if (this.clientCountry !== "fr") {
      const productSubMenu = this.menus[0].submenu as Array<any>;
      productSubMenu.splice(3, 1);
    }
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
