
import Vue from "vue";
import { getDynamicallyImage } from "@/helpers/dom";

export default Vue.extend({
  name: "AppStoryCard",
  props: {
    image: {
      type: String,
      required: true
    },
    hoverImage: {
      type: String
    },
    hasLink: {
      type: Boolean
    },
    content: {
      type: Object as () => {
        title: string;
        text: string;
        urlName?: string;
      }
    },
    shadow: {
      type: String as () =>
        | "none"
        | "top-right"
        | "top-left"
        | "bottom-right"
        | "bottom-left",
      default: "none"
    },
    withArrow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    importImage(hover: boolean) {
      return getDynamicallyImage(
        hover ? this.hoverImage || this.image : this.image
      );
    }
  }
});
