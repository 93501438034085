
import Vue from "vue";
import { setMetaData } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "Privacy",
  metaInfo(): any {
    return setMetaData("SEO.privacy");
  }
});
