
import Vue from "vue";

export default Vue.extend({
  name: "AppDropdown",
  props: {
    menu: {
      type: Object as () => {
        label: string;
        to?: { name: string };
        href?: string;
        submenu?: Array<{
          label: string;
          description: string;
          highlight: string;
          to: string;
        }>;
      },
      required: true
    },
    routeNames: {
      type: Array as () => Array<string>
    },
    hasTwoColumns: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropdownActive: false,
      isDropdownOpen: false,
      leftPosition: "0px"
    };
  },
  methods: {
    onOpen() {
      this.isDropdownOpen = true;
    },
    onClose() {
      this.isDropdownOpen = false;
    },
    getMenuItemsList(menu, column) {
      if (!this.hasTwoColumns) {
        return menu.submenu;
      }
      const listClone = [...[], ...menu.submenu];
      const half = Math.ceil(listClone.length / 2);
      if (listClone.length % 2 == 0) {
        return column === 0
          ? listClone.splice(0, half)
          : listClone.splice(-half);
      } else {
        return column === 0
          ? listClone.splice(0, half)
          : listClone.splice(-half + 1);
      }
    },
    isDropdownItemActive(to: string) {
      return this.$route.name === to;
    }
  },
  watch: {
    isDropdownOpen: function() {
      if (this.isDropdownOpen) {
        setTimeout(() => {
          const buttonWidth = this.$refs.dropdownBtn
            ? this.$refs.dropdownBtn["$el"].offsetWidth
            : 0;
          const wrapperWidth = this.$refs.dropdownWrapper
            ? (this.$refs.dropdownWrapper as HTMLElement).offsetWidth
            : 0;
          this.leftPosition = "-" + (wrapperWidth - buttonWidth) / 2 + "px";
        }, 100);
      }
    },
    $route: function(route) {
      this.isDropdownActive = this.routeNames.includes(route.name);
    }
  }
});
