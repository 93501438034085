
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import AppTestimonialCard from "@/components/AppTestimonialCard.vue";
import AppOfferCard from "@/components/AppOfferCard.vue";
import { normalizeHeight } from "@/helpers/dom";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom";

export default Vue.extend({
  name: "SolutionSubcontractors",
  metaInfo(): any {
    return setMetaData("SEO.solution.subcontractors");
  },
  components: {
    AppTestimonialCard,
    AppOfferCard
  },
  mounted() {
    const elements = document.querySelectorAll(
      ".testimonial-wrapper .testimonial-card"
    );
    normalizeHeight(elements);
  },
  updated() {
    const elements = document.querySelectorAll(
      ".testimonial-wrapper .testimonial-card"
    );
    normalizeHeight(elements);
  },
  methods: {
    importIcon(icon: string) {
      return getDynamicallyImage(icon, true);
    },
    importImages(path: string) {
      return getDynamicallyImage(path);
    },
    ...mapActions(["setSelectedTestemonialIndexes"])
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
