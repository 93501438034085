
import Vue from "vue";
import { setMetaData } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "AboutPartnership",
  metaInfo(): any {
    return setMetaData("SEO.about.partnership");
  },
  data() {
    return {
      isSubmited: false,
      form: {
        firstname: null,
        surname: null,
        company: null,
        phone: null,
        email: null,
        message: null
      }
    };
  },
  methods: {
    submitRegister() {
      if (this.$refs.form && !this.$refs.form["invalid"]) {
        this.isSubmited = true;
        this.$notify({
          group: "appNotifications",
          type: "notification-success",
          title: "Form submission",
          text: "Your submission was a success!"
        });
      }
    }
  }
});
