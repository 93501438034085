import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 10000
});

export default {
  async getPrices(): Promise<{ data: { data: any }; status: number }> {
    return await apiClient.get("/plan");
  }
};
