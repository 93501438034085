
import Vue from "vue";
import { getDynamicallyImage } from "@/helpers/dom";

export default Vue.extend({
  name: "AppOfferCard",
  props: {
    image: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    shadow: {
      type: String as () =>
        | "none"
        | "top-right"
        | "top-left"
        | "bottom-right"
        | "bottom-left",
      default: "none"
    }
  },
  methods: {
    importImage() {
      return getDynamicallyImage(this.image);
    }
  }
});
