import i18n from "@/i18n";
import router from "@/router";

export function setMetaData(path: string): any {
  return {
    title: i18n.t(path + ".title"),
    meta: [
      {
        vmid: "og:title",
        property: "og:title",
        content: i18n.t(path + ".title")
      },
      {
        vmid: "description",
        name: "description",
        content: i18n.t(path + ".description")
      },
      {
        vmid: "og:description",
        property: "og:description",
        content: i18n.t(path + ".description")
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: i18n.t(path + ".keywords")
      }
    ],
    link: [{ rel: "canonical", href: router.currentRoute.fullPath }],
    htmlAttrs: {
      lang: i18n.locale,
      amp: true
    }
  };
}

export function getStyle(oElm, strCssRule): any {
  var strValue = "";
  if (document.defaultView && document.defaultView.getComputedStyle) {
    strValue = document.defaultView
      .getComputedStyle(oElm, "")
      .getPropertyValue(strCssRule);
  } else if (oElm.currentStyle) {
    strCssRule = strCssRule.replace(/-(\w)/g, function(strMatch, p1) {
      return p1.toUpperCase();
    });
    strValue = oElm.currentStyle[strCssRule];
  }
  return strValue;
}

export function getDynamicallyImage(
  path: string,
  isIcon?: boolean
): NodeRequire {
  let folder = "images/";
  if (isIcon) {
    folder = "icons/";
  }
  return require("../assets/" + folder + path);
}

export function isInsideSection(section: HTMLElement, callback: Function) {
  const sectionTopPosition = section.offsetTop;
  const sectionHeight = section.clientHeight;
  if (
    window.scrollY >= sectionTopPosition - window.innerHeight &&
    window.scrollY <= sectionTopPosition + sectionHeight
  ) {
    callback(true);
  } else {
    callback(false);
  }
}

export function normalizeHeight(elements: NodeList) {
  setTimeout(() => {
    const elementsArray = [].slice.call(elements) as Array<HTMLElement>;
    const higherElement = Math.max.apply(
      null,
      elementsArray.map(e => e.scrollHeight)
    );
    elementsArray.forEach(element => {
      const elementPadding = getStyle(element, "padding").split("px")[0] || 0;
      const elementHeight = higherElement - elementPadding * 2;
      element.style.minHeight = elementHeight + "px";
    });
  }, 0);
}
