
import Vue from "vue";
import AppStory from "@/components/AppStory.vue";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "ProductDeductions",
  metaInfo(): any {
    return setMetaData("SEO.product.deductions");
  },
  components: {
    AppStory
  },
  methods: {
    importIcon(icon: string) {
      return getDynamicallyImage(icon, true);
    },
    importImages(path: string) {
      return getDynamicallyImage(path);
    }
  }
});
