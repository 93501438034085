
import Vue from "vue";
import AppSwitch from "@/components/AppSwitch.vue";

export default Vue.extend({
  name: "RentalsInput",
  inheritAttrs: false,
  components: {
    AppSwitch
  },
  props: {
    alignment: {
      type: String as () => "start" | "center" | "end"
    },
    bordered: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    rentalsFrequency: {
      type: String,
      required: true
    }
  },
  methods: {
    updateFrequency(frequency: string) {
      this.$emit("updateFrequency", frequency);
    },
    updateValue(value: number) {
      this.$emit("input", value);
    },
    focusInInput() {
      this.$emit("focusInInput");
    }
  }
});
