
import Vue from "vue";
import { setMetaData } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "AboutRecruitment",
  metaInfo(): any {
    return setMetaData("SEO.about.recruitment");
  }
});
