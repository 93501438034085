
import Vue from "vue";

export default Vue.extend({
  name: "FullPage",
  props: {
    page: {
      type: String as () => "trial" | "demo"
    }
  }
});
