
import Vue from "vue";
import AppOfferCard from "@/components/AppOfferCard.vue";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "ProductReporting",
  metaInfo(): any {
    return setMetaData("SEO.product.reporting");
  },
  components: {
    AppOfferCard
  },
  methods: {
    importIcon(icon: string) {
      return getDynamicallyImage(icon, true);
    }
  }
});
