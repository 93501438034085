import Vue from "vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import fr from "vee-validate/dist/locale/fr.json";
import de from "vee-validate/dist/locale/de.json";
import "@/helpers/formRules.ts";

localize({ en, fr, de });

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
