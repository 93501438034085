import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { merge } from "lodash";

Vue.use(VueI18n);

export const LOCALES_MAP = {
  FR: "fr",
  CH: "fr-ch",
  BE: "fr-be",
  DE: "de",
  EN: "en"
};

export const LOCALES = [
  { label: "en", code: "en" },
  { label: "fr", code: "fr" },
  { label: "fr", code: "fr-ch" },
  { label: "fr", code: "fr-be" },
  { label: "de", code: "de" },
  { label: "de", code: "de-ch" }
];

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      if (locale.split("-").length > 1) {
        const originLocale = merge({}, locales(key.split("-")[0] + ".json"));
        const regionLocale = locales(key);
        messages[locale] = merge(originLocale, regionLocale);
      } else {
        messages[locale] = locales(key);
      }
    }
  });
  return messages;
}

export default new VueI18n({
  locale: "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages()
});
