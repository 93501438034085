
import Vue from "vue";
import AppStory from "@/components/AppStory.vue";
import { mapState } from "vuex";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom";

export default Vue.extend({
  name: "ProductOccupation",
  metaInfo(): any {
    return setMetaData("SEO.product.occupation");
  },
  components: {
    AppStory
  },
  methods: {
    importImage(path: string) {
      return getDynamicallyImage(path);
    }
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
