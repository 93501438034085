
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { localize } from "vee-validate";
import { LOCALES } from "@/i18n";
import { getLocalizedUrl } from "@/router/index";

export default Vue.extend({
  name: "LocaleSelect",
  data() {
    return {
      languages: LOCALES
    };
  },
  methods: {
    setLocale(locale: string) {
      if (locale !== this.$i18n.locale) {
        if (locale === "de" && this.clientCountry === "fr-ch") {
          locale = "de-ch";
        }
        this.setClientLanguage(locale);
        localStorage.setItem("locale", locale);
        const newPath = getLocalizedUrl(
          this.$route,
          this.$route.name as string
        );
        if (newPath) {
          this.$router.replace({ path: newPath, params: { locale } });
        } else {
          this.$router.replace({ params: { locale } });
        }
      }
    },
    ...mapActions(["setClientLanguage"])
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
