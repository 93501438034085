
import Vue from "vue";
import { getDynamicallyImage } from "@/helpers/dom";

export default Vue.extend({
  name: "AppClientCard",
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    importImage(path: string) {
      return getDynamicallyImage(path);
    },
    redirectToPage() {
      const name = this.$t("home.clients.cards")[this.index].urlName;
      this.$router.push({ name });
    }
  }
});
