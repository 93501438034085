
import Vue from "vue";
import AppStoryCard from "@/components/AppStoryCard.vue";
import { normalizeHeight } from "@/helpers/dom";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AppStory",
  components: {
    AppStoryCard
  },
  props: {
    images: {
      type: Array as () => Array<string>,
      require: true
    },
    hoverImages: {
      type: Array as () => Array<string>
    },
    content: {
      type: Array as () => Array<{
        title: string;
        text: string;
        urlName?: string;
      }>,
      require: true
    },
    shadows: Array as () => Array<
      "none" | "top-right" | "top-left" | "bottom-right" | "bottom-left"
    >,
    withArrows: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoaded: false
    };
  },
  methods: {
    resizeCards(): void {
      (this as any).isLoaded = true;
      const elements = document.querySelectorAll(".story-wrapper .story-card");
      normalizeHeight(elements);
    },
    handleLoad(): void {
      if (!(this as any).isLoaded) {
        (this as any).resizeCards();
      }
    }
  },
  computed: {
    ...mapState(["clientLanguage"])
  },
  watch: {
    clientLanguage: function(language) {
      (this as any).resizeCards();
    }
  },
  mounted() {
    const cardImg = document.querySelector(".story-wrapper .story-card img");
    if (cardImg) {
      cardImg.addEventListener("load", (this as any).handleLoad);
    }
  },
  destroyed() {
    const cardImg = document.querySelector(".story-wrapper .story-card img");
    if (cardImg) {
      cardImg.removeEventListener("load", (this as any).handleLoad);
    }
  }
});
