var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-main',{staticClass:"testimonial-page window",class:{ mobile: !_vm.$vuetify.breakpoint.mdAndUp }},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"window-content"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10","offset-sm":"1"}},[_c('div',{staticClass:"testimonial_icon"},[_c('img',{attrs:{"src":require("@/assets/images/common/quotation_dark.svg"),"alt":"quotation mark","width":"50px"}})])]),_c('v-col',{attrs:{"cols":"12","sm":"10","offset-sm":"1"}},[_c('div',{staticClass:"testimonial_title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("testemonials")[_vm.index].title)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("testemonials")[_vm.index].client)+" ")])])]),_c('v-col',{attrs:{"cols":"12","sm":"10","offset-sm":"1"}},[_c('iframe',{attrs:{"width":"100%","height":"500","src":_vm.$t('testemonials')[_vm.index].url,"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('v-col',{attrs:{"cols":"12","sm":"10","offset-sm":"1"}},[_c('v-btn',{staticClass:"button-secondary",class:{ mg_r_15: _vm.$vuetify.breakpoint.mdAndUp },attrs:{"outlined":"","ripple":false,"disabled":_vm.isPreviousDisabled(),"to":{
                  name: 'testimonial',
                  params: { index: _vm.getPreviousIndex() }
                }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.previousTestimony"))+" ")]),_c('v-btn',{staticClass:"button-main",attrs:{"outlined":"","ripple":false,"disabled":_vm.isNextDisabled(),"to":{
                  name: 'testimonial',
                  params: { index: _vm.getNextIndex() }
                }}},[_vm._v(" "+_vm._s(_vm.$t("global.actions.nextTestimony"))+" ")])],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }