/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import { LOCALES, LOCALES_MAP } from "@/i18n";
import i18n from "@/i18n";
import ClientService from "@/services/clientService";
import store from "@/store/index";

import Home from "@/views/Home.vue";
import Entrance from "@/views/ProductEntrance.vue";
import Occupation from "@/views/ProductOccupation.vue";
import Exit from "@/views/ProductExit.vue";
import Inspection from "@/views/ProductInspection.vue";
import Extranet from "@/views/ProductExtranet.vue";
import Inventory from "@/views/ProductInventory.vue";
import Monitoring from "@/views/ProductMonitoring.vue";
import Concierge from "@/views/ProductConcierge.vue";
import Communication from "@/views/ProductCommunication.vue";
import Reporting from "@/views/ProductReporting.vue";
import Deductions from "@/views/ProductDeductions.vue";
import API from "@/views/ProductApi.vue";
import One from "@/views/ProductOne.vue";
import Resolve from "@/views/ProductResolve.vue";
import Agents from "@/views/SolutionAgents.vue";
import Landlords from "@/views/SolutionLandlords.vue";
import Managers from "@/views/SolutionManagers.vue";
import Residences from "@/views/SolutionResidences.vue";
import Tertiary from "@/views/SolutionTertiary.vue";
import Seasonal from "@/views/SolutionSeasonal.vue";
import Subcontractors from "@/views/SolutionSubcontractors.vue";
import Bailiffs from "@/views/SolutionBailiffs.vue";
import Offers from "@/views/Offers.vue";
import Who from "@/views/AboutWho.vue";
import Recruitment from "@/views/AboutRecruitment.vue";
import Testimonials from "@/views/Testimonials.vue";
import Partners from "@/views/AboutPartners.vue";
import Partnership from "@/views/AboutPartnership.vue";
import Demo from "@/views/Demo.vue";
import Trial from "@/views/Trial.vue";
import Privacy from "@/views/Privacy.vue";
import Testimonial from "@/views/Testimonial.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

export const routeMap = {
  entrance: { parent: "product" },
  occupation: { parent: "product" },
  exit: { parent: "product" },
  inspection: { parent: "product" },
  extranet: { parent: "product" },
  inventory: { parent: "product" },
  monitoring: { parent: "product" },
  concierge: { parent: "product" },
  communication: { parent: "product" },
  reporting: { parent: "product" },
  deductions: { parent: "product" },
  api: { parent: "product" },
  one: { parent: "product" },
  resolve: { parent: "product" },
  agents: { parent: "solution" },
  landlords: { parent: "solution" },
  managers: { parent: "solution" },
  residences: { parent: "solution" },
  tertiary: { parent: "solution" },
  seasonal: { parent: "solution" },
  subcontractors: { parent: "solution" },
  bailiffs: { parent: "solution" },
  offers: { params: true },
  who_are_we: { parent: "about" },
  recruitment: { parent: "about" },
  testimonials: { parent: "about" },
  partners: { parent: "about" },
  partnership: { parent: "about" },
  demo: {},
  trial: {},
  privacy: {},
  testimonial: { params: true }
};

export function getLocalizedUrl(to: any, page: string): string | null {
  const routeMapItem = routeMap[page];
  if (!routeMapItem) {
    return null;
  }
  const pathFragments = to.path.split("/");
  const newFragment = i18n.t(
    "SEO." +
      (routeMapItem.parent ? routeMapItem.parent + "." : "") +
      page +
      ".path"
  );
  let index = pathFragments.length - 1;
  if (routeMapItem.params && (to.params.index || to.params.product)) {
    index = index - 1;
  }
  if (pathFragments[index] === newFragment) {
    return null;
  }
  if (routeMapItem.parent) {
    pathFragments[index - 1] = i18n.t("SEO." + routeMapItem.parent + ".path");
  }
  pathFragments[1] = i18n.locale;
  pathFragments[index] = newFragment;
  return pathFragments.join("/");
}

const RootComponent = {
  render(h) {
    return h("router-view");
  }
};

const setLocaleUrl = (to, next, page) => {
  const newPath = getLocalizedUrl(to, page);
  if (newPath) {
    return next(newPath);
  }
  next();
};

const routes = [
  {
    path: "/",
    redirect: "/no-locale"
  },
  {
    path: "/:locale",
    component: RootComponent,
    props: true,
    async beforeEnter(to, from, next) {
      store.dispatch("togglePageLoading");
      let countryCode;
      try {
        const country = await ClientService.getClientCountry();
        countryCode = country.data.data.country_code;
        if (!LOCALES_MAP[countryCode]) {
          store.dispatch("setClientCountry", "fr-ch");
        } else {
          store.dispatch("setClientCountry", LOCALES_MAP[countryCode]);
        }
      } catch (error) {
        store.dispatch("setClientCountry", "fr-ch");
      }
      const locale = to.params.locale;
      const storageLocale = localStorage.getItem("locale");
      store.dispatch("setClientLanguage", to.params.locale);
      store.dispatch("togglePageLoading");
      if (
        !LOCALES.map(l => l.code).includes(locale) &&
        storageLocale &&
        LOCALES.map(l => l.code).includes(storageLocale)
      ) {
        store.dispatch("setClientLanguage", storageLocale);
        return next(storageLocale);
      } else if (!LOCALES.map(l => l.code).includes(locale)) {
        if (countryCode && !LOCALES_MAP[countryCode]) {
          store.dispatch("setClientLanguage", "en");
          return next("en");
        } else {
          store.dispatch("setClientLanguage", store.state.clientCountry);
          return next(store.state.clientCountry);
        }
      }
      return next();
    },
    children: [
      {
        path: "",
        alias: [
          i18n.getLocaleMessage("fr")["SEO"]["home"]["path"],
          i18n.getLocaleMessage("en")["SEO"]["home"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["home"]["path"]
        ],
        name: "home",
        component: Home
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["product"]["path"],
        name: "product",
        component: RootComponent,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["product"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["product"]["path"]
        ],
        redirect: { name: "entrance" },
        children: [
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["entrance"][
              "path"
            ],
            name: "entrance",
            component: Entrance,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["entrance"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["entrance"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "entrance");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["occupation"][
              "path"
            ],
            name: "occupation",
            component: Occupation,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["occupation"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["occupation"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "occupation");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["exit"]["path"],
            name: "exit",
            component: Exit,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["exit"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["exit"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "exit");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["inspection"][
              "path"
            ],
            name: "inspection",
            component: Inspection,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["inspection"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["inspection"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "inspection");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["extranet"][
              "path"
            ],
            name: "extranet",
            component: Extranet,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["extranet"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["extranet"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "extranet");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["inventory"][
              "path"
            ],
            name: "inventory",
            component: Inventory,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["inventory"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["inventory"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "inventory");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["monitoring"][
              "path"
            ],
            name: "monitoring",
            component: Monitoring,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["monitoring"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["monitoring"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "monitoring");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["concierge"][
              "path"
            ],
            name: "concierge",
            component: Concierge,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["concierge"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["concierge"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "concierge");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"][
              "communication"
            ]["path"],
            name: "communication",
            component: Communication,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["communication"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["communication"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "communication");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["reporting"][
              "path"
            ],
            name: "reporting",
            component: Reporting,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["reporting"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["reporting"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "reporting");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["deductions"][
              "path"
            ],
            name: "deductions",
            component: Deductions,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["deductions"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["product"]["deductions"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "deductions");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["api"]["path"],
            name: "api",
            component: API,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["api"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["api"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "api");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["one"]["path"],
            name: "one",
            component: One,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["one"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["one"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "one");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["product"]["resolve"][
              "path"
            ],
            name: "resolve",
            component: Resolve,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["product"]["resolve"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["product"]["resolve"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "resolve");
            }
          }
        ]
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["path"],
        name: "solution",
        component: RootComponent,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["solution"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["solution"]["path"]
        ],
        redirect: { name: "agents" },
        children: [
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["agents"][
              "path"
            ],
            name: "agents",
            component: Agents,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["agents"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["agents"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "agents");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["landlords"][
              "path"
            ],
            name: "landlords",
            component: Landlords,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["landlords"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["landlords"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "landlords");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["managers"][
              "path"
            ],
            name: "managers",
            component: Managers,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["managers"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["managers"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "managers");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["residences"][
              "path"
            ],
            name: "residences",
            component: Residences,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["residences"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["residences"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "residences");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["tertiary"][
              "path"
            ],
            name: "tertiary",
            component: Tertiary,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["tertiary"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["tertiary"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "tertiary");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["seasonal"][
              "path"
            ],
            name: "seasonal",
            component: Seasonal,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["seasonal"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["seasonal"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "seasonal");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"][
              "subcontractors"
            ]["path"],
            name: "subcontractors",
            component: Subcontractors,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["subcontractors"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["subcontractors"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "subcontractors");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["solution"]["bailiffs"][
              "path"
            ],
            name: "bailiffs",
            component: Bailiffs,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["solution"]["bailiffs"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["solution"]["bailiffs"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "bailiffs");
            }
          }
        ]
      },
      {
        path:
          i18n.getLocaleMessage("fr")["SEO"]["offers"]["path"] + "/:product?",
        name: "offers",
        component: Offers,
        props: true,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["offers"]["path"] + "/:product?",
          i18n.getLocaleMessage("de")["SEO"]["offers"]["path"] + "/:product?"
        ],
        beforeEnter(to, from, next) {
          return setLocaleUrl(to, next, "offers");
        }
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["about"]["path"],
        name: "about",
        component: RootComponent,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["about"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["about"]["path"]
        ],
        redirect: { name: "who_are_we" },
        children: [
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["about"]["who_are_we"][
              "path"
            ],
            name: "who_are_we",
            component: Who,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["about"]["who_are_we"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["about"]["who_are_we"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "who_are_we");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["about"]["recruitment"][
              "path"
            ],
            name: "recruitment",
            component: Recruitment,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["about"]["recruitment"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["about"]["recruitment"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "recruitment");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["about"]["testimonials"][
              "path"
            ],
            name: "testimonials",
            component: Testimonials,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["about"]["testimonials"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["about"]["testimonials"][
                "path"
              ]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "testimonials");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["about"]["partners"][
              "path"
            ],
            name: "partners",
            component: Partners,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["about"]["partners"]["path"],
              i18n.getLocaleMessage("de")["SEO"]["about"]["partners"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "partners");
            }
          },
          {
            path: i18n.getLocaleMessage("fr")["SEO"]["about"]["partnership"][
              "path"
            ],
            name: "partnership",
            component: Partnership,
            alias: [
              i18n.getLocaleMessage("en")["SEO"]["about"]["partnership"][
                "path"
              ],
              i18n.getLocaleMessage("de")["SEO"]["about"]["partnership"]["path"]
            ],
            beforeEnter(to, from, next) {
              return setLocaleUrl(to, next, "partnership");
            }
          }
        ]
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["demo"]["path"],
        name: "demo",
        component: Demo,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["demo"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["demo"]["path"]
        ],
        beforeEnter(to, from, next) {
          return setLocaleUrl(to, next, "demo");
        }
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["trial"]["path"],
        name: "trial",
        component: Trial,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["trial"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["trial"]["path"]
        ],
        beforeEnter(to, from, next) {
          return setLocaleUrl(to, next, "trial");
        }
      },
      {
        path: i18n.getLocaleMessage("fr")["SEO"]["privacy"]["path"],
        name: "privacy",
        component: Privacy,
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["privacy"]["path"],
          i18n.getLocaleMessage("de")["SEO"]["privacy"]["path"]
        ],
        beforeEnter(to, from, next) {
          return setLocaleUrl(to, next, "privacy");
        }
      },
      {
        path:
          i18n.getLocaleMessage("fr")["SEO"]["testimonial"]["path"] + "/:index",
        name: "testimonial",
        component: Testimonial,
        props(route) {
          const props = { ...route.params };
          props.index = +props.index;
          return props;
        },
        alias: [
          i18n.getLocaleMessage("en")["SEO"]["testimonial"]["path"] + "/:index",
          i18n.getLocaleMessage("de")["SEO"]["testimonial"]["path"] + "/:index"
        ],
        beforeEnter(to, from, next) {
          return setLocaleUrl(to, next, "testimonial");
        }
      },
      {
        path: "404",
        name: "404",
        component: NotFound
      },
      {
        path: "*",
        redirect: { name: "404" }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router;
