import { render, staticRenderFns } from "./AppPageLoading.vue?vue&type=template&id=06d7681c&scoped=true"
import script from "./AppPageLoading.vue?vue&type=script&lang=ts"
export * from "./AppPageLoading.vue?vue&type=script&lang=ts"
import style0 from "./AppPageLoading.vue?vue&type=style&index=0&id=06d7681c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d7681c",
  null
  
)

export default component.exports