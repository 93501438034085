
import Vue from "vue";

export default Vue.extend({
  name: "AppSteppers",
  props: {
    amountSteps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      default: 1
    }
  },
  methods: {
    moveLeft() {
      this.$emit("foward");
    },
    moveRight() {
      this.$emit("backward");
    }
  }
});
