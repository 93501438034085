
import Vue from "vue";
import AppStory from "@/components/AppStory.vue";
import AppClientCard from "@/components/AppClientCard.vue";
import { Carousel, Slide } from "vue-carousel";
import {
  setMetaData,
  isInsideSection,
  getDynamicallyImage,
  normalizeHeight
} from "@/helpers/dom.ts";
import { mapState } from "vuex";
import ICountUp from "vue-countup-v2";

export default Vue.extend({
  name: "Home",
  metaInfo(): any {
    return setMetaData("SEO.home");
  },
  components: {
    AppStory,
    AppClientCard,
    Carousel,
    Slide,
    ICountUp
  },
  data() {
    return {
      statisticsSectionActive: false,
      counterOptions: {
        useEasing: true,
        useGrouping: true,
        separator: " "
      },
      isLoaded: false
    };
  },
  methods: {
    getImage(path: string): NodeRequire {
      return getDynamicallyImage(path);
    },
    resizeCards(): void {
      (this as any).isLoaded = true;
      const elements = document.querySelectorAll(
        ".client-wrapper .client-card"
      );
      normalizeHeight(elements);
    },
    handleLoad(): void {
      if (!(this as any).isLoaded) {
        (this as any).resizeCards();
      }
    },
    handleScroll(event) {
      isInsideSection(
        document.querySelector(
          ".home .statistics .row > div:last-of-type"
        ) as HTMLElement,
        isInside => (this.statisticsSectionActive = isInside)
      );
    },
    setPlayStoreUrl(locale) {
      const loc = locale === "en" ? "GB" : locale.split("-")[0].toUpperCase();

      return `https://play.google.com/store/apps/details?id=com.homepad.mobile.android.app&hl=${
        locale.split("-")[0]
      }&gl=${loc}`;
    },
    setAppStoreUrl(locale) {
      const urls = {
        en:
          "https://apps.apple.com/gb/app/homepad-app-rental-inspection/id6443623665",
        fr:
          "https://apps.apple.com/fr/app/homepad-app-%C3%A9tat-des-lieux/id6443623665",
        de:
          "https://apps.apple.com/de/app/homepad-app-rental-inspection/id6443623665"
      };
      const loc = locale.split("-")[0];

      return urls[loc];
    }
  },
  computed: {
    ...mapState(["clientCountry"])
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    const cardImg = document.querySelector(".client-wrapper .client-card img");
    if (cardImg) {
      cardImg.addEventListener("load", (this as any).handleLoad);
    }
  },
  destroyed() {
    const cardImg = document.querySelector(".client-wrapper .client-card img");
    if (cardImg) {
      cardImg.removeEventListener("load", (this as any).handleLoad);
    }
    document.removeEventListener("scroll", this.handleScroll);
  }
});
