
import Vue from "vue";
import SubscriptionService from "@/services/subscriptionService";
import FullPage from "@/components/FullPage.vue";
import AppSteppers from "@/components/AppSteppers.vue";
import RentalsInput from "@/components/RentalsInput.vue";
import { setMetaData } from "@/helpers/dom.ts";
import { mapState } from "vuex";

export default Vue.extend({
  name: "Trial",
  metaInfo(): any {
    return setMetaData("SEO.trial");
  },
  components: {
    FullPage,
    AppSteppers,
    RentalsInput
  },
  data() {
    return {
      amountSteps: 6,
      step: 1,
      form: {
        2: {
          First_Name: null,
          Last_Name: null,
          Company: null,
          Langue:
            this.$i18n.locale.indexOf("en") !== -1 ? "Anglais" : "Français",
          Nombre_de_locations_an: 0,
          Canal: "Web Form / Formulaire web",
          Origine_Prospect: "Search Engine / Google",
          Detail_Origine: "Trial form",
          Lead_Status: "Dropped"
        },
        3: {
          Street: null,
          Zip_Code: null,
          City: null,
          Country: null
        },
        4: {
          Phone: null
        },
        5: {
          Email: null,
          Accept_privacy: false,
          Accept_terms_and_conditions: false,
          Lead_Status: "Completed"
        }
      },
      rentalsFrequency: this.$t("global.consume.frequency")[0].variable,
      isLoading: false,
      apiResponseId: null
    };
  },
  beforeMount() {
    (window as any).Intercom("update", { hide_default_launcher: true });
    this.$recaptchaInstance.showBadge();
  },
  beforeDestroy() {
    (window as any).Intercom("update", { hide_default_launcher: false });
    this.$recaptchaInstance.hideBadge();
  },
  methods: {
    // TODO: use a mixin to prevent duplicated code with demo
    moveFoward() {
      this.step++;
    },
    moveBackward() {
      this.step--;
    },
    isFowardDisabled(invalid: boolean): boolean {
      return (
        (this.step === 1 && this.form[2].Nombre_de_locations_an <= 0) ||
        (this.step !== 1 && (invalid || this.isLoading))
      );
    },
    updateFrequency(frequency: string) {
      this.rentalsFrequency = frequency;
    },
    resetRentalsAmount() {
      if (+this.form[2].Nombre_de_locations_an === 0) {
        this.form[2].Nombre_de_locations_an = null as any;
      }
    },
    async submitRegister() {
      if (this.step === 1) {
        this.moveFoward();
      } else {
        if (this.$refs.form && !this.$refs.form["invalid"]) {
          await this.$recaptchaLoaded();
          const token = await this.$recaptcha("trial");
          if (token) {
            this.isLoading = true;
            try {
              if (this.step === 2) {
                if (
                  this.rentalsFrequency ===
                  this.$t("global.consume.frequency")[0].variable
                ) {
                  this.form[2].Nombre_de_locations_an =
                    this.form[2].Nombre_de_locations_an * 12;
                }
                this.form[2].Nombre_de_locations_an = (this.form[2]
                  .Nombre_de_locations_an + "") as any;
              }
              const response = await SubscriptionService.setTrial(
                this.form[this.step],
                this.apiResponseId
              );
              this.apiResponseId = response.data.data.id;
              this.step++;
            } catch (error) {
              this.$notify({
                group: "appNotifications",
                type: "notiffication-error",
                title: this.$t("global.submit.error.title") as string,
                text: this.$t("global.submit.error.text") as string
              });
            }
            this.isLoading = false;
          }
        }
      }
    }
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
