import { render, staticRenderFns } from "./SolutionManagers.vue?vue&type=template&id=669c7009"
import script from "./SolutionManagers.vue?vue&type=script&lang=ts"
export * from "./SolutionManagers.vue?vue&type=script&lang=ts"
import style0 from "./../assets/scss/views/managers.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports