import Vue from "vue";
import App from "./App.vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import VueMeta from "vue-meta";
import Notifications from "vue-notification";
import vClickOutside from "v-click-outside";
import VueResizeText from "vue-resize-text";
import { VueReCaptcha } from "vue-recaptcha-v3";
import "./plugins/vee-validate";
import "./plugins/intercom.js";
import * as Sentry from "@sentry/vue";

// Sentry configuration
Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_SENTRY_ENVIRONMENT
});

Vue.config.productionTip = false;

/*Register automatically all the components (.vue) in the components/global folder*/
const requireComponent = require.context(
  "./components/global",
  false,
  /[A-Z]\w+\.(vue)$/
);
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1"))
  );
  Vue.component(componentName, componentConfig.default || componentConfig);
});
/*--------------------------------------------------------------------------*/

Vue.use(VueMeta, { refreshOnceOnNavigation: true });
Vue.use(Notifications);
Vue.use(vClickOutside);
Vue.use(VueResizeText);
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA,
  loaderOptions: {
    autoHideBadge: true,
    explicitRenderParameters: {
      badge: "bottomleft",
      tabindex: 99999999
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
