
import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";

export default Vue.extend({
  name: "AppCarousel",
  props: {
    elemPerPage: {
      type: Number,
      default: 3
    },
    numElem: {
      type: Number,
      default: 3
    },
    startBy: {
      type: Number,
      default: 0
    }
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
    isNavigationEnabled() {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.elemPerPage < this.numElem
        : 1 < this.numElem;
    }
  }
});
