import Vue from "vue";
import Vuex from "vuex";
import { ActionContext } from "vuex";
import i18n from "./../i18n";
import { localize } from "vee-validate";

export interface IState {
  pageLoading: boolean;
  clientCountry: string;
  clientLanguage: string;
  creditsAmount: number;
  selectedTestemonialIndexes: Array<number>;
}

Vue.use(Vuex);

const state = {
  pageLoading: false,
  clientCountry: "fr-ch",
  clientLanguage: "fr",
  creditsAmount: 200,
  selectedTestemonialIndexes: []
};

const mutations = {
  togglePageLoading(state: IState) {
    state.pageLoading = !state.pageLoading;
  },
  setClientCountry(state: IState, country: string) {
    state.clientCountry = country;
  },
  setClientLanguage(state: IState, language: string) {
    i18n.locale = language;
    localize(language.split("-")[0]);
    state.clientLanguage = language;
  },
  setCreditsAmount(state: IState, amount: number) {
    state.creditsAmount = amount;
  },
  setSelectedTestemonialIndexes(
    state: IState,
    testemonialIndexes: Array<number>
  ) {
    state.selectedTestemonialIndexes = testemonialIndexes;
  }
};

const actions = {
  togglePageLoading: ({ commit }: ActionContext<IState, IState>) =>
    commit("togglePageLoading"),
  setClientCountry: (
    { commit }: ActionContext<IState, IState>,
    payload: string
  ) => commit("setClientCountry", payload),
  setClientLanguage: (
    { commit }: ActionContext<IState, IState>,
    payload: string
  ) => commit("setClientLanguage", payload),
  setCreditsAmount: (
    { commit }: ActionContext<IState, IState>,
    payload: number
  ) => commit("setCreditsAmount", payload),
  setSelectedTestemonialIndexes: (
    { commit }: ActionContext<IState, IState>,
    payload: Array<number>
  ) => commit("setSelectedTestemonialIndexes", payload)
};

const getters = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
