
import Vue from "vue";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "AboutPartners",
  metaInfo(): any {
    return setMetaData("SEO.about.partners");
  },
  methods: {
    importImage(path: string) {
      return getDynamicallyImage(path);
    }
  }
});
