var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-nav-dropdown",on:{"mouseover":_vm.onOpen,"mouseleave":_vm.onClose}},[_c('v-btn',{ref:"dropdownBtn",class:{ 'v-btn--dropActive': _vm.isDropdownActive },attrs:{"text":"","ripple":false,"to":_vm.menu.to ? { name: _vm.menu.to, params: { locale: _vm.$i18n.locale } } : '',"href":_vm.menu.href,"target":_vm.menu.href ? '_blank' : null}},[_vm._v(" "+_vm._s(_vm.$t(_vm.menu.label))+" "),(_vm.menu.submenu)?_c('v-icon',{class:{ open: _vm.isDropdownOpen },attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")]):_vm._e()],1),_c('transition',{attrs:{"enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut","duration":{ enter: 200, leave: 300 }}},[(_vm.menu.submenu && _vm.isDropdownOpen)?_c('div',{ref:"dropdownWrapper",staticClass:"dropdown-wrapper",style:({ left: _vm.leftPosition })},[_c('div',[_c('ul',_vm._l((_vm.getMenuItemsList(_vm.menu, 0)),function(submenu,index){return _c('li',{key:index,class:{ highlight: submenu.highlight }},[_c('div',{staticClass:"app-nav-dropdown_item",class:{ active: _vm.isDropdownItemActive(submenu.to) }},[(submenu.href)?_c('a',{staticClass:"app-nav-dropdown_title button_link",attrs:{"href":submenu.href,"target":"_blank"}},[_c('div',{staticClass:"app-nav-dropdown_title bold"},[_vm._v(" "+_vm._s(_vm.$t(submenu.label))+" ")]),(submenu.description)?_c('div',{staticClass:"app-nav-dropdown_description"},[_vm._v(" "+_vm._s(_vm.$t(submenu.description))+" ")]):_vm._e()]):_c('router-link',{attrs:{"to":{
                  name: submenu.to,
                  params: { locale: _vm.$i18n.locale }
                }},nativeOn:{"click":function($event){return _vm.onClose.apply(null, arguments)}}},[_c('div',{staticClass:"app-nav-dropdown_title bold"},[_vm._v(" "+_vm._s(_vm.$t(submenu.label))+" ")]),(submenu.description)?_c('div',{staticClass:"app-nav-dropdown_description"},[_vm._v(" "+_vm._s(_vm.$t(submenu.description))+" ")]):_vm._e()])],1)])}),0),(_vm.hasTwoColumns)?_c('div'):_vm._e(),(_vm.hasTwoColumns)?_c('ul',_vm._l((_vm.getMenuItemsList(_vm.menu, 1)),function(submenu,index){return _c('li',{key:index,class:{ highlight: submenu.highlight }},[_c('div',{staticClass:"app-nav-dropdown_item",class:{ active: _vm.isDropdownItemActive(submenu.to) }},[_c('router-link',{attrs:{"to":{
                  name: submenu.to,
                  params: { locale: _vm.$i18n.locale }
                }},nativeOn:{"click":function($event){return _vm.onClose.apply(null, arguments)}}},[_c('div',{staticClass:"app-nav-dropdown_title bold"},[_vm._v(" "+_vm._s(_vm.$t(submenu.label))+" ")]),(submenu.description)?_c('div',{staticClass:"app-nav-dropdown_description"},[_vm._v(" "+_vm._s(_vm.$t(submenu.description))+" ")]):_vm._e()])],1)])}),0):_vm._e()])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }