
import Vue from "vue";

export default Vue.extend({
  name: "AppAccordion",
  props: {
    theme: {
      type: String as () => "light" | "dark",
      default: "light"
    },
    menus: {
      type: Array as () => Array<{
        label: string;
        to?: string;
        submenu?: Array<{
          label: string;
          description: string;
          to: string;
        }>;
      }>,
      required: true
    }
  },
  methods: {
    isAccordionTitleActive(menu: any) {
      if (!menu.submenu && !menu.to) {
        return false;
      } else if (!menu.submenu && menu.to) {
        return this.$route.name === menu.to;
      }
      return menu.submenu.map(s => s.to).includes(this.$route.name);
    },
    isAccordionItemActive(to: any) {
      return this.$route.name === to;
    }
  }
});
