
import Vue from "vue";

export default Vue.extend({
  name: "AppTestimonialCard",
  props: {
    index: {
      type: Number,
      required: true
    },
    shadow: {
      type: String as () =>
        | "none"
        | "top-right"
        | "top-left"
        | "bottom-right"
        | "bottom-left",
      default: "none"
    }
  },
  methods: {
    selectTestimonial() {
      this.$emit("navigateToTestimonial");
      this.$router.push({
        name: "testimonial",
        params: { index: this.index.toString() }
      });
    }
  }
});
