import { extend } from "vee-validate";
import { required, email, numeric } from "vee-validate/dist/rules";
import i18n from "./../i18n";

const phoneRegex = /^\+?\d+$/m;

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("phone", {
  validate(value) {
    return phoneRegex.test(value);
  },
  message: () => i18n.t("global.phone.format") as string
});
