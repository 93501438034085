import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 10000
});

async function submitForm(
  path: "trial" | "demo" | "contact",
  payload: any,
  id?: number | null
): Promise<any> {
  try {
    let fullPath = "/" + path;
    let method = "post";
    let options;
    if (id) {
      fullPath += "/" + id;
      method = "patch";
      if (path === "trial" && payload.Phone) {
        options = { params: { step: "phone" } };
      }
      if (path === "trial" && payload.Email) {
        options = { params: { step: "email" } };
      }
    }
    return await apiClient[method](fullPath, payload, options);
  } catch (error) {
    return Promise.reject(error);
  }
}

export default {
  async setTrial(payload: any, id?: number | null): Promise<any> {
    return await submitForm("trial", payload, id);
  },
  async setDemo(payload: any, id?: number | null): Promise<any> {
    return await submitForm("demo", payload, id);
  },
  async setContact(payload: any): Promise<any> {
    return await submitForm("contact", payload);
  }
};
