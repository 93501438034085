import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 10000
});

export default {
  async getClientCountry(): Promise<{
    data: { data: { country_code: string } };
    status: number;
  }> {
    return await apiClient.get("/country");
  }
};
