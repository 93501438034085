
import Vue from "vue";
import { mapActions } from "vuex";
import AppTestimonialCard from "@/components/AppTestimonialCard.vue";
import { setMetaData, normalizeHeight } from "@/helpers/dom";

export default Vue.extend({
  name: "Testimonials",
  metaInfo(): any {
    return setMetaData("SEO.about.testimonials");
  },
  components: {
    AppTestimonialCard
  },
  mounted() {
    const elements = document.querySelectorAll(
      ".testimonial-wrapper .testimonial-card"
    );
    normalizeHeight(elements);
  },
  updated() {
    const elements = document.querySelectorAll(
      ".testimonial-wrapper .testimonial-card"
    );
    normalizeHeight(elements);
  },
  methods: {
    getShadow(index: number) {
      switch (index) {
        case 0:
          return "bottom-left";
        case 1:
          return "top-right";
        case 2:
          return "bottom-right";
        case 3:
          return "bottom-left";
        case 4:
          return "top-left";
        case 5:
          return "bottom-right";
      }
    },
    ...mapActions(["setSelectedTestemonialIndexes"])
  }
});
