
import Vue from "vue";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "ProductConcierge",
  metaInfo(): any {
    return setMetaData("SEO.product.concierge");
  },
  methods: {
    importIcon(icon: string) {
      return getDynamicallyImage(icon, true);
    }
  }
});
