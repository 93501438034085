
import Vue from "vue";
import PriceService from "@/services/priceService";
import SubscriptionService from "@/services/subscriptionService";
import RentalsInput from "@/components/RentalsInput.vue";
import AppSlider from "@/components/AppSlider.vue";
import { mapState } from "vuex";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "Offers",
  metaInfo(): any {
    return setMetaData("SEO.offers");
  },
  components: {
    // RentalsInput
    // AppSlider
  },
  async created() {
    this.isLoading = true;
    try {
      const pricesResponse = await PriceService.getPrices();
      if (pricesResponse.status === 200) {
        this.plansList = pricesResponse.data.data.plans;
        this.selectedPlan = this.plansList[this.creditsAmount];
        const deletedOffer = this.clientCountry === "fr-ch" ? 2 : 4;
        delete this.selectedPlan[deletedOffer];
        this.regionCurrency = this.selectedPlan[1].currency_symbol;
        this.isLoading = false;
      } else {
        //console.log(error);
      }
    } catch (error) {
      //console.log(error);
    }
  },
  beforeMount() {
    if (this.selectedProduct !== "one") {
      this.$recaptchaInstance.showBadge();
    }
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
  data() {
    return {
      selectedProduct: this.$route.params.product || "one",
      rentalsAmount: 0,
      rentalsFrequency: this.$t("global.consume.frequency")[0].variable,
      creditsMin: 25,
      creditsMax: 500,
      creditsStep: 25,
      plansList: {},
      selectedPlan: {},
      regionCurrency: "€",
      byUnit: {
        selectedUnitIndex: undefined,
        offers: [
          {
            index: "u0",
            credits: 1,
            price: "12,49"
          },
          {
            index: "u1",
            credits: 2,
            price: "22,49",
            economy: "2,50"
          },
          {
            index: "u2",
            credits: 4,
            price: "37,49",
            economy: "12,50"
          },
          {
            index: "u3",
            credits: 6,
            price: "52,49",
            economy: "22,50"
          }
        ]
      },
      contactUs: false,
      isSubmited: false,
      form: {
        resolve: {
          First_Name: null,
          Last_Name: null,
          Company: null,
          Phone: null,
          Email: null,
          Message: null,
          Langue: null,
          Canal: "Web Form / Formulaire web",
          Origine_Prospect: "Search Engine / Google",
          Detail_Origine: "Resolve form"
        },
        pack: {
          First_Name: null,
          Last_Name: null,
          Company: null,
          Phone: null,
          Email: null,
          Message: null,
          Langue: null,
          Canal: "Web Form / Formulaire web",
          Origine_Prospect: "Search Engine / Google",
          Detail_Origine: "One&Resolve form"
        }
      },
      isLoading: false,
      isFormSubmiting: false
    };
  },
  methods: {
    updateOffer(product: "one" | "resolve" | "pack") {
      this.selectedProduct = product;
      this.isSubmited = false;
      if (product !== "one") {
        this.$recaptchaInstance.showBadge();
      } else {
        this.$recaptchaInstance.hideBadge();
      }
      this.$router.replace({
        path:
          "/" +
          this.$i18n.locale +
          "/" +
          this.$i18n.t("SEO.offers.path") +
          "/" +
          product,
        params: { locale: this.$i18n.locale, product }
      });
    },
    updateFrequency(frequency: string) {
      this.rentalsFrequency = frequency;
    },
    getImage(path: string): NodeRequire {
      return getDynamicallyImage(path);
    },
    getSugestedPlan() {
      let rentalPerYear = +this.rentalsAmount;
      if (
        this.rentalsFrequency ===
        this.$t("global.consume.frequency")[0].variable
      ) {
        rentalPerYear = rentalPerYear * 12;
      }
      let closestArray = Object.keys(this.plansList).filter(
        plan => +plan <= +rentalPerYear
      );
      let closest = closestArray[closestArray.length - 1];
      if (!closest) {
        closest = Object.keys(this.plansList)[0];
      }
      this.creditsValue = +closest;
    },
    resetForm() {
      this.form[this.selectedProduct].First_Name = null;
      this.form[this.selectedProduct].Last_Name = null;
      this.form[this.selectedProduct].Company = null;
      this.form[this.selectedProduct].Phone = null;
      this.form[this.selectedProduct].Email = null;
      this.form[this.selectedProduct].Message = null;
    },
    async submitRegister() {
      if (this.$refs.form && !this.$refs.form["invalid"]) {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("offers_" + this.selectedProduct);
        if (token) {
          this.isFormSubmiting = true;
          try {
            this.form[this.selectedProduct].Langue =
              this.$i18n.locale.indexOf("en") !== -1 ? "Anglais" : "Français";
            const response = await SubscriptionService.setContact(
              this.form[this.selectedProduct]
            );
            this.resetForm();
            this.isSubmited = true;
          } catch (error) {
            this.$notify({
              group: "appNotifications",
              type: "notiffication-error",
              title: this.$t("global.submit.error.title") as string,
              text: this.$t("global.submit.error.text") as string
            });
          }
          this.isFormSubmiting = false;
        }
      }
    }
  },
  computed: {
    creditsValue: {
      get(): number {
        return this.creditsAmount;
      },
      set(value) {
        this.$store.commit("setCreditsAmount", value);
      }
    },
    ...mapState(["creditsAmount", "clientLanguage", "clientCountry"])
  },
  watch: {
    rentalsAmount: function() {
      this.getSugestedPlan();
    },
    rentalsFrequency: function() {
      this.getSugestedPlan();
    },
    creditsValue: function() {
      this.contactUs = this.creditsValue === 500;
      const value = this.creditsValue as number;
      if (value === 150) {
        this.creditsMin = 50;
        this.creditsStep = 50;
      } else if (value < 150) {
        this.creditsMin = 25;
        this.creditsStep = 25;
      }
      this.selectedPlan = this.plansList[value];
      if (this.selectedPlan) {
        let deletedOffer = this.clientCountry === "fr-ch" ? 2 : 4;
        delete this.selectedPlan[deletedOffer];
        if (value === 25) {
          deletedOffer = this.clientCountry === "fr-ch" ? 4 : 3;
          delete this.selectedPlan[deletedOffer];
        }
      }
    }
  }
});
