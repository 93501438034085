
import Vue from "vue";

export default Vue.extend({
  name: "AppSwitch",
  inheritAttrs: false,
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    values: {
      type: Array as () => Array<{ label: string; variable: string | number }>,
      required: true
    },
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    toggleValue(event: any, value: string) {
      if (value !== this.value && this.$refs.movingChip) {
        const currentWidth = this.$refs.movingChip["clientWidth"];
        if (value === this.values[1].variable) {
          this.$refs.movingChip["style"].left = currentWidth + 8 + "px";
        } else {
          this.$refs.movingChip["style"].left = "5px";
        }
        this.$emit("toggleValue", value);
      }
    },
    getContentValue(): string {
      return this.values.filter(v => v.variable === this.value)[0].label;
    }
  }
});
