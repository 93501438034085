
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { setMetaData } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "Testimonial",
  metaInfo(): any {
    return setMetaData("SEO.testimonial");
  },
  props: {
    index: {
      type: Number,
      required: true
    }
  },
  mounted() {
    if (this.selectedTestemonialIndexes.length === 0) {
      this.setSelectedTestemonialIndexes(
        this.$t("testimonials_page.testimonies")
      );
    }
  },
  methods: {
    isPreviousDisabled(): boolean {
      const indexPosition = this.selectedTestemonialIndexes.indexOf(this.index);
      return indexPosition <= 0;
    },
    isNextDisabled(): boolean {
      const indexPosition = this.selectedTestemonialIndexes.indexOf(this.index);
      return indexPosition === this.selectedTestemonialIndexes.length - 1;
    },
    getPreviousIndex(): number {
      const indexPosition = this.selectedTestemonialIndexes.indexOf(this.index);
      return this.selectedTestemonialIndexes[indexPosition - 1];
    },
    getNextIndex(): number {
      const indexPosition = this.selectedTestemonialIndexes.indexOf(this.index);
      return this.selectedTestemonialIndexes[indexPosition + 1];
    },
    ...mapActions(["setSelectedTestemonialIndexes"])
  },
  computed: {
    ...mapState(["selectedTestemonialIndexes"])
  }
});
