
import Vue from "vue";
import AppSwitch from "@/components/AppSwitch.vue";
import AppStory from "@/components/AppStory.vue";
import { mapState } from "vuex";
import { setMetaData, getDynamicallyImage } from "@/helpers/dom.ts";

export default Vue.extend({
  name: "ProductEntrance",
  metaInfo(): any {
    return setMetaData("SEO.product.entrance");
  },
  components: {
    AppSwitch,
    AppStory
  },
  data() {
    return {
      version: this.$t("product.entrance.statistics.versions")[0].variable
    };
  },
  methods: {
    importImage(path: string) {
      return getDynamicallyImage(path);
    },
    updateVersion(version: string) {
      this.version = version;
    }
  },
  computed: {
    ...mapState(["clientCountry"])
  }
});
